import request from '@/utils/request'


// 查询明星俱乐部列表
export function listClub(query) {
  return request({
    url: '/star/club/list',
    method: 'get',
    params: query
  })
}

// 查询明星俱乐部分页
export function pageClub(query) {
  return request({
    url: '/star/club/page',
    method: 'get',
    params: query
  })
}

// 查询明星俱乐部详细
export function getClub(data) {
  return request({
    url: '/star/club/detail',
    method: 'get',
    params: data
  })
}

// 新增明星俱乐部
export function addClub(data) {
  return request({
    url: '/star/club/add',
    method: 'post',
    data: data
  })
}

// 修改明星俱乐部
export function updateClub(data) {
  return request({
    url: '/star/club/edit',
    method: 'post',
    data: data
  })
}

// 删除明星俱乐部
export function delClub(data) {
  return request({
    url: '/star/club/delete',
    method: 'post',
    data: data
  })
}
